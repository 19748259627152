@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import "bootstrap-grid.css";
@import "header";

:root {
    --colorPrimaryDark : #0C2E9D;
    --colorPrimary     : #2446B5;
    --colorAccent      : #ABABAB;
}

* {
    box-sizing : border-box
}

body {
    font-family : 'Playfair Display', serif;
    font-size   : 1rem;
}

.ui.error.message, .ui.success.message, .ui.warning.message {
    display    : block;
    padding    : 5px 5px;
    font-size  : 12px;
    text-align : center;
}

.clearfix::after {
    clear   : both;
    content : '';
    display : block;
}

span.badge {
    position       : absolute;
    top            : 0;

    background     : red;
    height         : 16px;
    width          : auto;
    padding-left   : 5px;
    padding-right  : 5px;
    line-height    : 16px;
    vertical-align : middle;
    display        : block;
    border-radius  : 2px;
    text-align     : center;
    color          : white;
    font-size      : 10px;
}

.h-100 {
    height : 100%;
}

div.pdf_container {
    height : calc(100vh - 40px);
}

/*form data*/
.ui.dropdown, .ui.selection.dropdown {
    height         : 30px;
    min-height     : 30px;
    line-height    : 30px;
    vertical-align : middle;
    padding        : 0 5px;
}

.ui.dropdown, .ui.selection.dropdown .text {
    font-size : 13px;
}

.ui.selection.dropdown div.text {
    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
    width         : calc(100% - 10px);
    height        : 100%;
}

.ui.selection.dropdown > .delete.icon, .ui.selection.dropdown > .dropdown.icon, .ui.selection.dropdown > .search.icon {
    height  : 30px;
    padding : 8px 5px;
    margin  : -10px -10px 0 0;
}

.ui.negative.button, .ui.negative.buttons .button {
    background : #560C0C;
}

.ui.positive.button, .ui.positive.buttons .button {
    background : #153E05;
}

.ui.primary.button, .ui.primary.buttons .button {
    background : var(--colorPrimaryDark);
}

.ui.grey.label, .ui.grey.labels .label {
    background    : var(--colorPrimaryDark) !important;;

    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.flex_1 {
    flex : 1;
}

.custom-file-upload {
    border  : 1px solid #CCCCCC;
    display : inline-block;
    padding : 6px 12px;
    cursor  : pointer;
    width   : 100%;
}

.custom-file-upload.has_file {
    border : 1px solid #134E03;
}

.input_file {
    width    : 0.1px;
    height   : 0.1px;
    opacity  : 0;
    overflow : hidden;
    position : absolute;
    z-index  : -1;
}

img {
    max-height : 100%;
}

input.date_input {
    width          : 100%;
    height         : 29px;
    padding        : 0 10px;
    line-height    : 29px;
    vertical-align : middle;
    border-radius  : 5px;
    border         : solid 1px lightgray;

    &:focus {
        border  : solid 1px #C3C1C1;
        outline : none;
    }
}

.react-tel-input .form-control {
    height : 30px;
    width  : calc(100% - 0px);
}

div.floating_button {
    height         : 30px;
    width          : 30px;
    background     : var(--colorPrimaryDark);
    position       : absolute;
    bottom         : 5px;
    right          : 5px;
    border-radius  : 50%;
    cursor         : pointer;
    border         : solid 1px var(--colorPrimaryDark);
    line-height    : 30px;
    vertical-align : middle;
    text-align     : center;

    i {
        color     : white;
        font-size : 15px;
    }

    &:hover {
        background : white;

        i {
            color : var(--colorPrimaryDark);
        }
    }
}

/*form content*/
div.loan_form {
    padding : 5px;

    & > div {
        background    : whitesmoke;
        padding       : 10px;
        height        : 100%;
        border-radius : 5px;
    }
}

div.disburse_summary {
    text-align : center;

    div.title {
        font-weight   : bold;
        font-size     : 13px;
        color         : black;
        margin-bottom : 5px;
        margin-top    : 15px;
    }

    div.amount {
        color       : #034B03;
        font-size   : 25px;
        font-weight : bold;

        &.principal {
            font-size : 18px;
            color     : #03114B;
        }

        &.charges {
            font-size : 18px;
            color     : #4B0310;
        }
    }
}

.form {
    border-radius  : 0;
    padding        : 5px 5px;
    height         : 100%;
    background     : whitesmoke;
    display        : flex;
    flex-direction : column;

    &.ui.form {
        height : auto;
    }

    div.form_header {
        padding                 : 5px;
        background              : var(--colorPrimaryDark);
        border-top-left-radius  : 0;
        border-top-right-radius : 0;
        text-align              : center;
        font-weight             : bold;
        color                   : white;
        height                  : 30px;
        margin-bottom           : 5px;
    }

    div.form_content {
        overflow-y    : auto;
        flex          : 1;
        margin-bottom : 5px;
        overflow-x    : hidden;
        position      : relative;

        div.collateral_images {
            width    : 100%;
            padding  : 5px;
            position : relative;

            img {
                border : solid 2px white;
                width  : 100%;
                height : auto;
            }

            div.delete_image {
                position      : absolute;
                top           : 20px;
                left          : 20px;
                background    : red;
                height        : 20px;
                width         : 20px;
                cursor        : pointer;
                border-radius : 5px;
                text-align    : center;
                color         : white;
                font-size     : 12px;
            }
        }

        div.add_collateral {
            position      : absolute;
            bottom        : 55px;
            right         : 20px;
            height        : 30px;
            width         : 30px;
            border-radius : 50%;
            background    : var(--colorPrimaryDark);

            label {
                vertical-align : middle;
                line-height    : 30px;
                color          : white;
                display        : block;
                width          : 100%;
                height         : 100%;
                cursor         : pointer;
                text-align     : center;
            }
        }
    }

    div.form_footer {
        padding                    : 5px;
        background                 : white;
        border-bottom-left-radius  : 0;
        border-bottom-right-radius : 0;
        height                     : 38px;
    }
}

form.form {
    padding : 0;
}

.ui.form textarea, textarea {
    resize    : none;
    font-size : 13px;
}

input, optgroup, select, textarea {
    font-size : 13px;
}

.ui.input > input {
    padding        : 0 10px;
    line-height    : 28px;
    vertical-align : middle;
}

/*general table data*/
div.table_container {
    overflow-y : auto;
    overflow-x : auto;
}

.ui.table {
    margin : 0;

    thead {
        position   : sticky !important;
        top        : 0;
        z-index    : 2;
        background : var(--colorPrimaryDark);
    }

    tbody tr {
        &:hover {
            cursor : pointer;
        }

        &.reversed {
            color          : red;
            pointer-events : none;
        }
    }

    thead tr th, tbody tr td {
        vertical-align : middle;
    }

    &.has_total tbody tr:last-child, tbody tr.has_total {
        font-weight : 800;
        background  : rgba(85, 83, 83, 0.5) !important;
    }

    &.inverted {
        &.grey {
            background-color : #E2E2E2 !important;
            color            : #000000 !important;
        }

        &.selectable tbody tr:hover {
            //background : var(--colorPrimary) !important;
            background : rgba(85, 83, 83, 0.5) !important;
            color      : white !important;
        }

        &.striped tbody tr:nth-child(2n), &.striped > tr:nth-child(2n) {
            background-color : #D4D4D4
        }

        tr td {
            border-color : #C2BBBB !important;
        }
    }

    td, th {
        &.amount_row {
            width : 120px;
        }

        &.time_row {
            width : 140px;
        }
    }

    &.compact {
        td {
            padding : 10px 6px;

            &.spanned_color {
                background : #134E03;
                padding    : 0;
                position   : relative;

                div.days_spanned {
                    background : #560C0C;
                    height     : 37.56px;
                }

                div.date {
                    background     : transparent;
                    height         : 37.56px;
                    line-height    : 37.56px;
                    vertical-align : middle;
                    padding        : 0 5px;
                    color          : white;
                    position       : absolute;
                    left           : 0;
                    top            : 0;
                }
            }
        }
    }

    &:not(.structured) {
        thead, tbody tr {
            display      : table;
            width        : 100%;
            table-layout : fixed;
        }
    }

    button.ui.tiny {
        padding : 6px;

        i {
            font-size : 10px;
        }
    }
}

/*login data*/
div.login_container {
    position         : absolute;
    top              : 0;
    z-index          : 1500;
    height           : 100vh;
    background-color : rgb(208, 212, 220);
    padding          : calc((100vh - 175px) / 3) calc((100% - 350px) / 2);

    div.login_modal {
        padding    : 15px 20px;
        width      : 350px;
        height     : auto;
        background : white;

        h2 {
            display     : block;
            text-align  : center;
            font-size   : 25px;
            padding     : 20px 0 10px 0;
            font-family : 'Times New Roman', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }

        div.not_user {
            text-align    : center;
            margin-bottom : 5px;
            margin-top    : 5px;
            color         : black;
            font-size     : 90%;

            span.link {
                color           : var(--colorPrimaryDark);
                font-style      : italic;
                font-weight     : 600;
                cursor          : pointer;
                text-decoration : underline;
            }
        }
    }
}

/*App Settings*/
.settings_container {
    height : calc(100% - 0px);

    .ui.segment.tab_pane {
        height        : calc(100% - 55px);
        overflow-y    : auto;
        padding       : 5px;
        border-radius : 0;
    }
}

/*loan creation*/
.loan_info_container {
    height : calc(100% - 40px);

    .ui.segment.create_loan_pane {
        height        : calc(100% - 55px);
        overflow-y    : auto;
        padding       : 5px;
        border-radius : 0;

        div.client_payment_summary {
            height        : 75px;
            border        : solid 1px lightgray;
            background    : #FFFFFF;
            border-radius : 0;
            padding       : 5px;
            margin-bottom : 0;
        }

        div.loan_payments_parent {
            height : calc(100% - 80px);
        }

        .loan_other_data {
            height : 100%;

            .ui.segment.other_loan_pane {
                height        : calc(100% - 55px);
                padding       : 0;
                border-radius : 0;
            }
        }
    }
}

div.modify_loan {
    padding    : 10px;
    background : white;

    &.extensions {
        height : 525px;
    }

    &.charges {
        height : 380px;
    }

    &.payments {
        height : 500px;
    }

    &.reschedules {
        height : 250px;
    }
}


div.modal_div {
    height         : auto;
    max-height     : calc(100vh - 40px);
    background     : white;
    border-radius  : 0;
    padding        : 5px;
    overflow-y     : auto;
    display        : flex;
    flex-direction : column;

    div.form_header {
        padding                 : 5px;
        background              : var(--colorPrimaryDark);
        border-top-left-radius  : 0;
        border-top-right-radius : 0;
        text-align              : center;
        font-weight             : bold;
        color                   : white;
        height                  : 30px;
        margin-bottom           : 5px;
    }

    div.table_container {
        overflow-y : auto;
        overflow-x : auto;
        flex       : 1;
        background : whitesmoke;
        padding    : 0;

        & > table {
            min-width : 100%;
        }
    }

    div.form_content {
        overflow-y : auto;
        flex       : 1;
        overflow-x : hidden;
        position   : relative;

        &.rights_form, div.core_role_users {
            & > div.field {
                padding    : 5px 10px;
                background : whitesmoke;

                &:nth-child(2n-1) {
                    background : white;
                }
            }
        }
    }

    div.form_footer {
        padding    : 5px;
        margin-top : 5px;
        background : white;
        height     : 38px;
    }

    &.payment_modal {
        .payment_amount.target_unreached > input {
            background : #FF6600;
            color      : white;
        }

        .payment_amount.invalid > input {
            color : red;
        }

        .payment_amount.loan_complete > input {
            background : green;
            color      : white;
        }

        .payment_amount.valid > input {
            color : green;
        }
    }

    &.loan_types {
        height : 400px;
    }

    &.creditor_modal, &.salary_scales {
        height : calc(100vh - 150px);
    }

    &.creditor_payments {
        height : calc(100vh - 100px);
    }

    &.creditor_loan {
        height : calc(100vh - 50px);

        .creditor_schedules {
            height         : 100%;
            flex-direction : column;
            display        : flex;

            & > div.table_container {
                flex : 1;
            }

            & > div.creditor_buttons {
                background     : whitesmoke;
                padding        : 5px;
                margin-top     : 15px;
                flex-direction : row;
                display        : flex;

                & > div:first-child {
                    flex : 1;
                }

                button {
                    min-width : 120px;
                }
            }
        }
    }

    &.access_rights {
        height : calc(100vh - 100px);

        div.accordion {
            height     : calc(100% - 35px);
            overflow-y : auto;
        }
    }
}

.ui.table {
    tbody {
        td {
            white-space   : nowrap;
            overflow      : hidden;
            text-overflow : ellipsis;
        }
    }

    &.calendar_table {
        border-radius : 0;
        border        : solid 1px var(--colorPrimaryDark);

        thead {
            th {
                color      : var(--colorPrimaryDark);
                background : white;
                text-align : center;
                border     : none;
            }
        }

        tbody {
            background : white;

            div {
                div.month {
                    color       : white;
                    padding     : 3px;
                    font-size   : 10px;
                    line-height : 12px;
                }

                div.day {
                    color       : white;
                    padding     : 3px;
                    font-weight : 700;
                    font-size   : 150%;
                    margin-top  : 10px;
                }

                div.due_loan, div.pending, div.payments {
                    position       : absolute;
                    color          : white;
                    width          : auto;
                    height         : 15px;
                    line-height    : 15px;
                    vertical-align : middle;
                    font-size      : 10px;
                    cursor         : pointer;
                    border-radius  : 3px;
                    padding        : 0 5px 0px 5px;

                    &:hover {
                        background  : transparent;
                        font-size   : 15px;
                        font-weight : 600;
                        color       : #7E1818;
                    }
                }

                div.due_loan {
                    background : #7E1818;
                    top        : 3px;
                    left       : 3px;

                    &:hover {
                        color : #7E1818;
                    }
                }

                div.pending {
                    background : #D7391D;
                    bottom     : 3px;
                    right      : 3px;

                    &:hover {
                        color : #D7391D;
                    }
                }

                div.payments {
                    background : #246D0A;
                    bottom     : 3px;
                    left       : 3px;

                    &:hover {
                        color : #246D0A;
                    }
                }
            }

            div.even {
                background : var(--colorPrimaryDark);
            }

            div.odd {
                background : var(--colorPrimary);
            }

            div.even, div.odd {
                border : solid 1px transparent;

                &:hover {
                    border : solid 1px white;
                }
            }
        }
    }
}


div.my_pagination {
    text-align : center;

    & > div {
        display        : inline-block;
        min-width      : 40px;
        height         : 32px;
        border-top     : solid 1px lightgrey;
        border-bottom  : solid 1px lightgrey;
        text-align     : center;
        border-right   : solid 1px lightgrey;
        vertical-align : middle;
        line-height    : 32px;
        cursor         : pointer;

        &.select_container {
            width : 120px;

            div, input {
                border : none !important;
            }
        }

        &:first-child {
            border-left               : solid 1px lightgrey;
            border-bottom-left-radius : 5px;
            border-top-left-radius    : 5px;
        }

        &:last-child {
            border-bottom-right-radius : 5px;
            border-top-right-radius    : 5px;
        }
    }
}


div.multi_checks {
    padding       : 3px 3px;
    margin-bottom : 3px;
    border-radius : 2px;

    &:nth-child(2n) {
        background : #EEEEEE;
    }

    &:last-child {
        margin-bottom : 0;
    }
}
